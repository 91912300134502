import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import palette from '../shared/palette';
import { PrimaryHeading, CenteredParagraphText } from '../shared/typography';
import TopicsBar from './TopicsBar';

const Survey = () => {
  return (
    <Container>
      <PrimaryHeading>Surveys</PrimaryHeading>
      <CenteredParagraphText>
        To determine the impact of sleep and activity on other aspects of
        student life, we will send out weekly surveys via the WearDuke app. The
        topics of these surveys include:
      </CenteredParagraphText>
      <TopicsBar />
      <CenteredParagraphText style={{ marginBottom: '3rem' }}>
        Surveys will be open from 12am on Monday to 11:59pm on Tuesday. You must
        complete two of the weekly surveys each month to remain an active
        participant in this initiative. If you are unable to do so due to
        extenuating circumstances, please contact the study staff. See our{' '}
        <Link to="initiative/#policies" style={{ textDecoration: 'none' }}>
          <PoliciesLink>Policies</PoliciesLink>
        </Link>{' '}
        section for more details.
      </CenteredParagraphText>
    </Container>
  );
};

export default Survey;

const Container = styled.div`
  padding: 10rem 0rem;
`;

const PoliciesLink = styled.span`
  cursor: pointer;
  border-bottom: 1px solid ${palette.dark1};
  color: ${palette.dark1};
`;
