import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TopicsBar = () => {
  return (
    <Container>
      <Topic backgroundColor={backgroundColor.red} color={textColor.red}>
        <TopicTitle>Sleep</TopicTitle>
        <Icon icon="bed" size={iconSize} />
      </Topic>
      <Topic backgroundColor={backgroundColor.orange} color={textColor.orange}>
        <TopicTitle>Mental Health</TopicTitle>
        <Icon icon="brain" size={iconSize} />
      </Topic>
      <Topic backgroundColor={backgroundColor.yellow} color={textColor.yellow}>
        <TopicTitle color={textColor.yellow}>Caffeine Intake</TopicTitle>
        <Icon icon="mug-hot" size={iconSize} />
      </Topic>
      <Topic backgroundColor={backgroundColor.green} color={textColor.green}>
        <TopicTitle>Academics</TopicTitle>
        <Icon icon="book" size={iconSize} />
      </Topic>
      <Topic backgroundColor={backgroundColor.blue} color={textColor.blue}>
        <TopicTitle>General Health</TopicTitle>
        <Icon icon="heart" size={iconSize} />
      </Topic>
    </Container>
  );
};

export default TopicsBar;

const Container = styled.div`
  margin: 5rem auto;
  display: flex;
  padding: 0rem 3rem;
  justify-content: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Topic = styled.div`
  position: relative;
  padding: 2rem 1rem;
  height: 15rem;
  flex-basis: 15rem;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};

  :first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  :last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  @media (max-width: 700px) {
    :first-child {
      border-radius: 0px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    :last-child {
      border-radius: 0px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const TopicTitle = styled.p`
  font-size: 2rem;
  text-align: center;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 700px) {
    font-size: 7rem;
  }
`;

// const rainbow = {
//     red: '#FF0900',
//     orange: '#FF7F00',
//     yellow: '#FFEF00',
//     green: '#00F11D',
//     blue: '#0079FF'

// }

const iconSize = '5x';

const backgroundColor = {
  red: '#235F9C',
  orange: '#339999',
  yellow: '#F09905',
  green: '#CC3300',
  blue: '#993399',
};

// HEX #0577B1#005587

const textColor = {
  red: '#FFFFFF',
  orange: '#FFFFFF',
  yellow: '#FFFFFF',
  green: '#FFFFFF',
  blue: '#FFFFFF',
};
