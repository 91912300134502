import React from 'react';
import styled from 'styled-components';

import { center } from '../shared/mixins';
import palette from '../shared/palette';
import { PrimaryHeading, ParagraphText } from '../shared/typography';

const Wearables = () => {
  return (
    <Container>
      <PrimaryHeading>The Wearables</PrimaryHeading>
      <Body>
        <ParagraphText>
          Participants will be given either the{' '}
          <Link
            href="https://www.apple.com/apple-watch-series-3/?afid=p238%7CsSd5HV8XW-dc_mtid_1870765e38482_pcrid_294575383676_&cid=aos-us-kwgo-watch--slid--apple+watch+series+3-e-product-"
            target="_blank"
          >
            Apple Watch Series 3
          </Link>{' '}
          or the{' '}
          <Link href="https://www.fitbit.com/charge3" target="_blank">
            Fitbit Charge 3
          </Link>{' '}
          for their wearable device. iOS phone users will be instructed to
          download our app, which will push weekly surveys and study info.
          Android users will be emailed survey links and study info.
        </ParagraphText>
        <UserText>
          <ParagraphText style={{ marginBottom: '1rem' }}>
            <span style={{ fontWeight: 800 }}>Android users:</span> Fitbit
            Charge 3
          </ParagraphText>
          <ParagraphText>
            <span style={{ fontWeight: 800 }}>iPhone users:</span> Apple Watch 3
          </ParagraphText>
        </UserText>
        <ParagraphText>
          There are no extra costs associated with either device &mdash; if
          students desire to have a different band other than the basic version,
          they can purchase it on their own.
        </ParagraphText>
      </Body>
    </Container>
  );
};

export default Wearables;

const Container = styled.div`
  padding: 10rem 0rem;
  background-color: ${palette.light2};
`;

const Body = styled.div`
  ${center}
`;

const Link = styled.a`
  border-bottom: 1px solid ${palette.dark1};

  :visited,
  :link {
    color: ${palette.dark1};
    text-decoration: none;
  }
`;

const UserText = styled.div`
  padding-left: 5rem;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @media (max-width: 500px) {
    padding-left: 3rem;
  }
`;
