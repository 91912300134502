import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBed,
  faBrain,
  faMugHot,
  faBook,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';

import Page from '../shared/Page';
import Survey from './Survey';
import Wearables from './Wearables';
import Policies from './Policies';

// add font awesome icons
library.add(faBed);
library.add(faBrain);
library.add(faMugHot);
library.add(faBook);
library.add(faHeart);

const InitiativePage = () => {
  return (
    <Page>
      <Survey />
      <Wearables />
      <Policies />
    </Page>
  );
};

export default InitiativePage;
