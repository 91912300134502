import React from 'react';
import styled from 'styled-components';

import { center } from '../shared/mixins';
import { PrimaryHeading, ParagraphText } from '../shared/typography';

const Policies = () => {
  return (
    <Container id="policies">
      <PrimaryHeading>Policies</PrimaryHeading>
      <PolicySection>
        <PolicyHeader>Right to Withdraw</PolicyHeader>
        <ParagraphText>
          &#9;Students have the right to withdrawal from the study at any time.
          If a student decides to withdraw/drop out of the study, they must
          notify the study staff by email and schedule a time to return their
          wearable.
        </ParagraphText>
      </PolicySection>
      <PolicySection>
        <PolicyHeader>Non-Participation</PolicyHeader>
        <ParagraphText style={{ marginBottom: '2rem' }}>
          &#9;Participation is greatly valued and a key part of making the study
          a success. If the study staff notices that a student has not worn the
          wearable for at least 4 consecutive days in a 2-week period, a
          reminder to wear the device regularly in order to remain an active
          participant will be sent. If a student has not worn the wearable for
          at least 4 consecutive days for two of four weeks in a month, the
          student wlll be contacted to confirm that they are withdrawing from
          the study; if so, a time will be scheduled to collect the wearable.
        </ParagraphText>
        <ParagraphText>
          If a student does not respond to 1 survey, a reminder will be sent to
          encourage completion of at least two surveys every month. If a student
          does not complete 2 surveys in a month, study staff will contact the
          student to confirm if the student is withdrawing from the study and
          schedule a time to collect the wearable.
        </ParagraphText>
      </PolicySection>
      <PolicySection>
        <PolicyHeader>Replacement</PolicyHeader>
        <ParagraphText style={{ marginBottom: '2rem' }}>
          If the wearable breaks or is no longer functioning, students should
          contact the study staff. The study staff will meet with the student
          and inspect the wearable to determine whether the wearable needs to be
          replaced or not. If the wearable is deemed non-functional due to
          accident or unknown cause, the study staff will order a replacement.
          Wearables that are damaged due to carelessness or negligence will not
          be replaced by the study staff. One replacement will be provided per
          student. If the wearable is damaged more than once, the student is
          responsible for the second replacement. If the student elects not to
          purchase a replacement wearable, they will be withdrawn from the
          study.
        </ParagraphText>
        <ParagraphText style={{ marginBottom: '2rem' }}>
          If the wearable is lost, the student will be responsible for replacing
          the wearable in order to continue participation in the study. The Duke
          Office of Information Technology will be notified and the wearable
          will be de-registered.
        </ParagraphText>
        <ParagraphText>
          If the band is damaged, the study team will provide up to one (1)
          replacement band (standard).
        </ParagraphText>
      </PolicySection>
    </Container>
  );
};

export default Policies;

const Container = styled.div`
  padding: 10rem 0rem;
`;

const PolicySection = styled.div`
  ${center}

  :not(:last-child) {
    margin-bottom: 5rem;
  }
`;

const PolicyHeader = styled(ParagraphText)`
  font-weight: 600;
  margin-bottom: 1rem;

  @media (max-width: 500px) {
    font-weight: 800;
  }
`;
