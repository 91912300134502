import React from 'react';

import Layout from '../components/layout';
import InitiativePage from '../InitiativePage/InitiativePage';

export default () => (
  <Layout>
    <InitiativePage />
  </Layout>
);
